
import { httpPost } from '@/api';
import ChooseMan from '@/components/ChooseMan/ChooseMan.vue';
import CitySelect from '@/components/CitySelect/CitySelect.vue';
import FileContent from '@/components/FileContent/FileContent.vue';
import DictSelect from '@/components/DictSelect/DictSelect.vue';
import { CityData } from '@/types/type';
import { ajaxLoading, errorMessage, successMessage, setTitleLabel } from '@/utils/publicMethods';
import { defineComponent, ref } from 'vue';
import useFormData from './useFundingFormData';
export default defineComponent({
  components: { CitySelect, ChooseMan, FileContent, DictSelect },
  props: {
    /** 新增编辑表单状态 1新增 2编辑 */
    formType: {
      type: [Number],
      default: 1,
    },
    data: {
      type: [Object],
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const addressData = ref();
    let params: any = {};
    // 资金方状态

    if (props.formType === 2) {
      params = JSON.parse(JSON.stringify(Object.assign({ operateType: 2 }, props.data)));
      if (!params.licenseAttachment) {
        params.licenseAttachment = {};
        params.licenseAttachment.fundingBaseFileName = null;
        params.licenseAttachment.fundingBaseFileUrl = null;
      }
      if (!params.identityCardAttachment) {
        params.identityCardAttachment = {};
        params.identityCardAttachment.fundingBaseFileName = null;
        params.identityCardAttachment.fundingBaseFileUrl = null;
      }
      if (!params.openingPermitAttachment) {
        params.openingPermitAttachment = {};
        params.openingPermitAttachment.fundingBaseFileName = null;
        params.openingPermitAttachment.fundingBaseFileUrl = null;
      }
      const data = props.data as any;
      // 设置区域
      addressData.value = [data?.provinceId, data.cityId, data.areaId];
    }
    const { rules, formData, formRef, valCnEnNum, statusOptions } = useFormData(params);
    // 部门组件对象
    const principalRef = ref();

    // 获取省市区联动
    const getArea = (data: CityData) => {
      formData.addressProvince = data.province.name;
      formData.provinceId = data.province.id;
      formData.addressCity = data.city.name;
      formData.cityId = data.city.id;
      formData.addressDistrict = data.county.name;
      formData.areaId = data.county.id;
    };
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    const submitData = async () => {
      ajaxLoading.lock();
      // 转化自定义备注信息
      formData.customerRemark = formData.remark;
      const params = JSON.parse(JSON.stringify(formData));
      if (!params.licenseAttachment.fundingBaseFileName) {
        params.licenseAttachment = null;
      }
      if (!params.identityCardAttachment.fundingBaseFileName) {
        params.identityCardAttachment = null;
      }
      if (!params.openingPermitAttachment.fundingBaseFileName) {
        params.openingPermitAttachment = null;
      }

      const res = await httpPost('/malicrm/funding/saveFunding', params);
      if (res.code === 200) {
        if (props.formType === 1) {
          successMessage('保存成功');
        }
        if (props.formType === 2) {
          successMessage('编辑成功');
        }
        ajaxLoading.unLock();
        // 这里的解锁在页面返回解除掉，这样可以保证一定不会重复提交
        close(true);
      } else {
        errorMessage(res.message);
        ajaxLoading.unLock();
      }
    };
    /** 提交表单 */
    const saveFormData = async () => {
      formRef.value.validate((valid) => {
        if (valid) {
          submitData();
        } else {
          return false;
        }
      });
    };
    /** 获取营业执照 */
    const getBusinessLicense = (fileList: any[]) => {
      if (fileList.length > 0) {
        formData.licenseAttachment.fundingBaseFileName = fileList[0].name;
        formData.licenseAttachment.fundingBaseFileUrl = fileList[0].url;
      } else {
        formData.licenseAttachment.fundingBaseFileName = null;
        formData.licenseAttachment.fundingBaseFileUrl = null;
      }
    };
    /** 获取法人信息 */
    const getIdCards = (fileList: any[]) => {
      if (fileList.length > 0) {
        formData.identityCardAttachment.fundingBaseFileName = fileList[0].name;
        formData.identityCardAttachment.fundingBaseFileUrl = fileList[0].url;
      } else {
        formData.identityCardAttachment.fundingBaseFileName = null;
        formData.identityCardAttachment.fundingBaseFileUrl = null;
      }
    };
    /** 获取开户许可证 */
    const getPermitFileName = (fileList: any[]) => {
      if (fileList.length > 0) {
        formData.openingPermitAttachment.fundingBaseFileName = fileList[0].name;
        formData.openingPermitAttachment.fundingBaseFileUrl = fileList[0].url;
      } else {
        formData.openingPermitAttachment.fundingBaseFileName = null;
        formData.openingPermitAttachment.fundingBaseFileUrl = null;
      }
    };
    return {
      close,
      formData,
      getArea,
      addressData,
      saveFormData,
      rules,
      formRef,
      principalRef,
      getBusinessLicense,
      getIdCards,
      getPermitFileName,
      setTitleLabel,
      valCnEnNum,
      statusOptions,
    };
  },
});
