import { CrmFilterOptions, CrmTableOptions } from '@/types/type';
import { defineComponent, ref } from 'vue';
import { loadPage } from '@/utils/publicMethods';
export default () => {
  //lint
  const tableRef = ref();
  const filterOptions: CrmFilterOptions[] = [
    {
      type: 'input',
      prop: 'shortName',
      value: '',
      placeholder: '请输入资金方',
    },
    {
      type: 'signSelect',
      prop: 'status',
      value: '',
      placeholder: '请选择状态',
      options: [
        { value: 1, label: '可用' },
        { value: 0, label: '不可用' },
      ],
    },
  ];
  const columnList: CrmTableOptions[] = [
    {
      label: '资金方',
      prop: 'shortName',
      minWidth: 140,
    },
    {
      label: '资金额度(元)',
      prop: 'fundingAmountDesc',
      minWidth: 160,
    },
    {
      label: '占用额度(元)',
      component: defineComponent({
        template: `
          <div  :style="getFontColor(row)" @click="toOccupyList(row)">
              {{showCount(row)}}
          </div>
        `,
        props: ['row'],
        setup() {
          const getFontColor = (row) => {
            const res = 'color:#333;cursor:default';
            if (row.usedAmount !== null && row.usedAmount > 0) {
              return 'color:#2878ff';
            }
            return res;
          };
          const showCount = (row) => {
            if (row.usedAmount !== null) {
              return row.usedAmountDesc;
            } else {
              return '-';
            }
          };
          const toOccupyList = (row) => {
            if (row.usedAmount !== null && row.usedAmount > 0) {
              loadPage('OccupyList', { id: row.id });
            }
          };
          return { getFontColor, showCount, toOccupyList };
        },
      }),
      prop: 'usedAmountDesc',
      minWidth: 160,
    },
    {
      label: '剩余额度(元)',
      prop: 'leftAmountDesc',
      minWidth: 160,
    },
    {
      label: '抬头',
      prop: 'consigneeNameDesc',
      minWidth: 167,
    },
    {
      label: '口岸',
      prop: 'portNameDesc',
      minWidth: 168,
    },
    {
      label: '业务类型',
      prop: 'fundingBusinessTypeDesc',
      minWidth: 90,
    },
    {
      label: '状态',
      prop: 'statusDesc',
      minWidth: 70,
    },
    {
      label: '维护人',
      prop: 'principal',
      minWidth: 120,
    },
    {
      label: '备注',
      prop: 'remark',
      minWidth: 100,
    },
  ];
  return { filterOptions, columnList, tableRef };
};
