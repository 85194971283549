import { reactive, ref, toRefs } from 'vue';
type FormData = {
  shortName: string | null; // 资金方简称
  fullName: string | null; // 资金方全称
  principalId: any[]; // 维护人id
  principal: string | number | null; // 维护人
  addressDetail: string | null; // 联系地址-详细
  provinceId: string | number | null; // 省id
  addressProvince: string | null; // 联系地址-省份
  cityId: string | number | null; // 城市id
  addressCity: string | null; // 联系地址-城市
  areaId: string | number | null; // 区id
  addressDistrict: string; // 联系地址-区
  remark: string; // 备注
  status: number; // 资金方状态
  licenseAttachment: {
    // 营业执照附件信息
    fundingBaseFileId: string | null;
    fundingBaseFileName: string | null;
    fundingBaseFileUrl: string | null;
  };
  openingPermitAttachment: {
    // 开户许可证附件信息
    fundingBaseFileId: string | null;
    fundingBaseFileName: string | null;
    fundingBaseFileUrl: string | null;
  };
  identityCardAttachment: {
    // 法人身份证附件信息
    fundingBaseFileId: string | null;
    fundingBaseFileName: string | null;
    fundingBaseFileUrl: string | null;
  };
  [propName: string]: any;
};
export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  const formData: FormData = reactive({
    shortName: '', // 资金方简称
    fullName: '', // 资金方全称
    principalId: [], // 维护人id
    principal: '', // 维护人
    addressDetail: '', // 联系地址-详细
    provinceId: '', // 省id
    addressProvince: '', // 联系地址-省份
    cityId: '', // 城市id
    addressCity: '', // 联系地址-城市
    areaId: '', // 区id
    addressDistrict: '', // 联系地址-区
    remark: null, // 备注
    status: 1,
    licenseAttachment: {
      // 营业执照附件信息
      fundingBaseFileId: null,
      fundingBaseFileName: '',
      fundingBaseFileUrl: '',
    },
    openingPermitAttachment: {
      // 开户许可证附件信息
      fundingBaseFileId: null,
      fundingBaseFileName: '',
      fundingBaseFileUrl: '',
    },
    identityCardAttachment: {
      // 法人身份证附件信息
      fundingBaseFileId: null,
      fundingBaseFileName: '',
      fundingBaseFileUrl: '',
    },
    ...params,
  });
  const rules = reactive({
    shortName: [
      { required: true, message: '请输入资金方简称', trigger: 'blur' },
    ],
    fullName: [
      { required: true, message: '请输入资金方全称', trigger: 'blur' },
    ],
    principalId: [
      { required: true, message: '请选择维护人', trigger: 'change' },
    ],
    status: [
      { required: true, message: '请选择资金方状态', trigger: 'change' },
    ],
  });
  const customFormData = reactive({
    statusOptions: [
      {
        label: '可用',
        value: 1,
      },
      {
        label: '不可用',
        value: 0,
      },
    ],
  });
  const valCnEnNum = (e, prop: string) => {
    let value = e.target.value;
    value = value.replace(/[^a-zA-Z 0-9\u4E00-\u9FA5]/g, '');
    if (value) {
      formData[prop] = value;
    } else {
      formData[prop] = null;
    }
  };
  return { rules, formData, formRef, ...toRefs(customFormData), valCnEnNum };
};
