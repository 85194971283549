
import { defineComponent, ref } from 'vue';
import CrmList from '@/components/CrmList/CrmList.vue';
import FundingForm from './components/FundingForm/FundingForm.vue';
import useListData from './components/useListData';
import useVisible from '@/hooks/useVisible';
import { ajaxLoading, loadPage } from '@/utils/publicMethods';
import router from '@/router';
export default defineComponent({
  name: 'FundingList',
  components: { CrmList, FundingForm },
  props: {},
  setup() {
    const { filterOptions, columnList, tableRef } = useListData();
    const { visible, showVisible, formType, setFormType } = useVisible();
    const rowData = ref({});
    const listBottomData = ref({});
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    const rowClick = (row, column) => {
      if (column.label !== '占用额度(元)') {
        loadPage('FundingDetail', {
          id: row.fundingId,
          detailsId: row.fundingId,
          backFrom: '/basicInformation/funding',
        });
      }
    };
    // 打开新增弹窗
    const openDrawer = (formType: number): void => {
      setFormType(formType);
      showVisible();
    };
    const setRowData = (row) => {
      rowData.value = row;
    };
    const closeVisible = (status) => {
      visible.value = false;
      // 解除重复提交
      ajaxLoading.unLock();
      if (status) {
        refreshTable(status);
      }
    };
    const getFundingTotal = (res) => {
      if (res) {
        listBottomData.value = res;
      }
    };
    const getLoadPage = (url) => {
      router.push(url);
    };
    return {
      filterOptions,
      columnList,
      tableRef,
      refreshTable,
      visible,
      formType,
      showVisible,
      closeVisible,
      setFormType,
      rowClick,
      getLoadPage,
      rowData,
      setRowData,
      openDrawer,
      getFundingTotal,
      listBottomData,
    };
  },
});
